@import "config/config";
@import "helpers/fontawesome"; // fa-pseudo

/*
  <details
    class="
      ui-component-disclosure-item
      icon:{chevron*, none}
      overflow-fade:{white*, grey}
      cta:{left*, center, right}
      // Optional. Use for clamped summary based on content.
      clamp:{0*, 3, 4}
    "
    data-controller="disclosure-item"
    // Optional CTA values, if CTA should change on open/close. Requires CTA target.
    data-disclosure-item-cta-open-value="Read more"
    data-disclosure-item-cta-close-value="Read less"
    // Optional scroll block value. Defaults to "center".
    data-disclosure-item-scroll-block-value="start | center"
    // All attributes below are optional. Use for clamped summary based on content.
    data-action="resize@window->disclosure-item#toggleCtaVisibility"
    data-disclosure-item-expand-once-value="true"
    data-disclosure-item-clamp-value="4" // Required for iOS <= 16 (if clamp > 0)
  >
    // There are two forms of markup for this component:

    // 1. Separate summary and content. The content is not part of the summary. This is the
    //    default `details` behaviour. E.g. A "Show details" / "Hide details" toggle.
    <summary data-action="click->disclosure-item#toggle">
      <span data-disclosure-item-target="cta">Summary text here</span>
    </summary>
    <section>Content here</section>

    // 2. Clamped summary based on content. A clamped portion of the content is used to create
    //    the summary. E.g. A snippet of content followed by a "Read more" link.
    <summary data-action="click->disclosure-item#preventDefault">
      <section data-disclosure-item-target="summaryContent">
        Content here
      </section>
      <span data-action="click->disclosure-item#toggle"
            data-disclosure-item-target="cta"></span>
    </summary>
  </details>
*/

.ui-component-disclosure-item {
  width: 100%;

  > summary {
    box-sizing: border-box;
    user-select: none; // Prevent accidental text selection when toggling
    list-style: none; // Hide details marker

    // Hide details marker in Safari
    &::-webkit-details-marker {
      display: none;
    }

    [data-disclosure-item-target="summaryContent"] {
      flex: 1;
      position: relative;
      overflow: hidden;
      height: 0;
      margin-bottom: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  [data-disclosure-item-target="summaryContent"] + [data-disclosure-item-target="cta"] {
    margin-top: var(--space-3xs);
  }

  &[open] > summary {
    user-select: auto;

    [data-disclosure-item-target="summaryContent"] {
      height: auto;
      overflow: initial;
    }

    > [data-disclosure-item-target="cta"]:first-child {
      margin-bottom: var(--space-3xs);
    }
  }

  &[class*="overflow-fade"] {
    --fade-color: #fff;

    [data-disclosure-item-target="summaryContent"] {
      // Pseudo element for fade, only if CTA is visible
      &:not(:has(+ [data-disclosure-item-target="cta"][hidden]))::after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.5em;
        background-color: var(--fade-color);
        box-shadow: 0 0 10px 5px var(--fade-color);
      }
    }

    &.overflow-fade\:grey {
      --fade-color: #{$grey-100};
    }
  }

  [data-disclosure-item-target="cta"] {
    display: inline-block;
    text-decoration: underline;

    &, &::after {
      color: $sb-green;
      cursor: pointer;

      .theme\:premium & {
        color: $sb-gold;
      }
    }
  }

  &.cta\:center > summary > [data-disclosure-item-target="cta"] {
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &.cta\:right > summary > [data-disclosure-item-target="cta"] {
    display: block;
    width: fit-content;
    margin-left: auto;
    text-align: right;
  }

  // Pseudo-element chevron icon
  &:not(.icon\:none) {
    [data-disclosure-item-target="cta"]::after,
    &:not(:has([data-disclosure-item-target="cta"])) > summary::after {
      margin-left: 1ch;
      transition: transform .2s ease-out;
      @include fa-pseudo("\f078"); // fa-chevron-down
    }

    &[open] {
      > summary > [data-disclosure-item-target="cta"]::after,
      &:not(:has([data-disclosure-item-target="cta"])) > summary::after {
        transform: rotate(180deg);
      }

      &[data-disclosure-item-expand-once-value="true"] > summary::after {
        display: none;
      }
    }
  }

  // Separate summary and content
  &:not(:has([data-disclosure-item-target="cta"])) {
    > summary {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: var(--space-2xs);
      cursor: pointer;
    }
  }

  &[class*=clamp] {
    [data-disclosure-item-target="summaryContent"] {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      height: auto;
    }

    &[open] [data-disclosure-item-target="summaryContent"] {
      display: block;
      overflow: initial;

      &::after {
        display: none !important;
      }
    }
  }

  &.clamp\:3 [data-disclosure-item-target="summaryContent"] {
    -webkit-line-clamp: 3;
  }

  &.clamp\:4 [data-disclosure-item-target="summaryContent"] {
    -webkit-line-clamp: 4;
  }
}
