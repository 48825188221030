@import "../ui_components/helpers/units"; // rem

.checkout\:old {
  .checkout\:payment-options {
    :has(> img) {
      display: flex;
      gap: var(--space-xs);
    }

    img {
      // https://docs.klarna.com/marketing/solutions/grab-and-go/gb/advertising-brand-guidelines/logos-trademarks/#clear-space
      min-height: 30px;
      margin-top: calc(.4 * 30px);
    }
  }

  .checkout\:page-layout {
    &, & > * {
      margin-bottom: 0 !important;
    }

    > * {
      flex-basis: 100%; // no summary on mobile, so this removes flex gap

      @include tablet-portrait-up {
        flex-basis: auto;
      }
    }
  }

  .checkout\:upgrades,
  .checkout\:delivery-options,
  .checkout\:extra-options {
    justify-content: center;
    margin-bottom: $space !important;
  }

  .checkout\:extra-options {
    .ui-component-counter {
      label {
        flex-shrink: 1;
      }

      input[size] {
        width: 100% !important;
      }
    }
  }

  .checkout\:product-modal {
    > [tabindex] {
      padding-top: var(--space-xl);
      padding-bottom: 0;

      > article > * {
        margin-bottom: 0;
      }

      .ui-component-carousel {
        &, & > div {
          height: auto;
        }
      }

      .ui-component-status-bar {
        padding-left: 0;
        padding-right: 0;
      }
    }

    header {
      text-align: center;
      padding-bottom: $space-sm;
      border-bottom: $ui-border;
      font-weight: 700;
      font-size: 0.85rem;
    }
  }

  .checkout\:address-results {
    @include forms--input-appearance;

    & {
      flex-basis: auto !important; // override fieldset descendant style
      width: auto;
      max-height: sb-rem(360px);
      overflow: auto;
    }

    label {
      font-weight: 400;
    }
  }

  .checkout\:summary-card {
    min-width: sb-rem(360px);
    max-width: sb-rem(600px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $space-lg;
    text-align: left;
    background-color: #fff;

    .ui-component-voucher {
      max-width: clamp(180px, 75%, 280px);
      min-width: auto;
      margin-top: calc(-1 * var(--space-3xl));
      margin-bottom: $space;
      font-size: 75%;

      .utils\:wave {
        font-size: 325%;

        &::after {
          max-height: 50%;
        }
      }
    }

    .accordion-item > :first-child {
      justify-content: center !important; // center "Details" toggle link
    }

    header {
      h4 {
        display: flex;
        justify-content: space-between;
        margin-bottom: $space-xxs;
      }

      > button:last-child {
        opacity: 1 !important; // undo `header:raised` style
      }
    }

    p:last-child {
      margin-bottom: 0;
    }

    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;

      th, td {
        padding-bottom: $space-3xs;
      }

      th + td {
        text-align: right;
        vertical-align: top;
      }

      tbody th, tfoot th {
        width: 75%;
        font-weight: 400;
      }

      tbody tr:last-child {
        th, td {
          padding-bottom: $space-sm;
        }
      }

      tfoot {
        tr:first-child, tr:last-child {
          border-top: $ui-border;

          th, td {
            padding-top: $space-xs;
            padding-bottom: $space-sm;
            font-weight: 700;
          }
        }

        tr:last-child {
          th, td {
            padding-bottom: 0;
          }
        }
      }
    }

    small {
      display: inline-flex;
      align-items: baseline;
      line-height: $space-sm;

      i {
        margin-right: $space-3xs;
      }
    }

    .ui-component-list > li:not(:last-child) {
      margin-bottom: $space-sm;
    }
  }

  .checkout\:sidebar-summary {
    display: none;

    @include tablet-portrait-up {
      display: flex;
      align-self: flex-start;
    }

    ul {
      margin-bottom: 0;
    }
  }

  .checkout\:applied-discounts {
    padding: 0 $space-xs;
    background: #fff;
    border: $ui-border;
    border-radius: $ui-border-radius;

    .ui-component-notice {
      margin-top: $space-xs;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      tr:not(:last-child) {
        border-bottom: $ui-border;
      }

      td {
        padding: $space-xs 0;

        &:not(:first-child) {
          text-align: right;
          white-space: nowrap;
        }
      }
    }
  }

  .checkout\:savvy-inputs {
    @media only screen and (min-width: map-get($_mq-breakpoints, tablet-portrait-up)) and (max-width: 1099px) {
      flex-wrap: wrap !important;
    }

    > label, > fieldset {
      flex-basis: 100%;
    }

    > fieldset {
      margin-bottom: 0;
    }
  }

  .checkout\:discount-modal-content {
    h3 {
      margin-top: $space;
      margin-bottom: $space-xxs;
      padding-bottom: $space-xxs;
      border-bottom: $ui-border;
    }
  }

  .checkout\:voucher-payment {
    .checkout\:details-summary {
      --image-height: 66px;
      overflow: unset;
      gap: var(--space-sm);
  
      > section:first-of-type { // image and venue name
        display: flex;
        gap: $space-xs;
  
        > :first-child {
          container-type: inline-size;
          flex-basis: 112px;
  
          img {
            border-radius: 4px;
            object-fit: cover;
            height: 100%;
            max-height: 100cqw;
          }
        }
  
        > :last-child {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 4px;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.25;
  
          > :last-child span {
            font-size: 12px;
          }
        }
      }
  
      > section:nth-of-type(2) { // check-in and check-out info
        display: flex;
        gap: $space-xs;
  
        > div {
          font-size: 14px;
          > :nth-child(2) {
            font-size: 16px;
            font-weight: 700;
          }
        }
  
        > :first-child {
          flex: 1;
        }
  
        > :not(:first-child) {
          width: 50%;
          padding-left: 12px;
          border-left: $ui-border;
        }
      }
  
      > section:nth-of-type(3) { // package details
        summary > section {
          display: flex;
          flex-direction: column;
          gap: $space-xs;
        }
  
        summary > section > section {
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
          display: flex;
          flex-direction: column;
          gap: var(--space-3xs);
  
          > :first-child { // section heading
            font-size: 14px;
            font-weight: 400;
          }
  
          .special-offers {
            font-size: 12px;
            display: inline-block;
            line-height: 1.2;
            color: $sb-purple-500;
            border-radius: 3px;
            padding: 8px;
            background-color: $sb-purple-100;
          }
        }
      }

      section > div:first-child {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .ui-component-disclosure-item > summary {
        & > :first-child {
          display: flex;
          flex-direction: column;
          gap: var(--space-sm);
        }

        & > :last-child {
          margin-top: 0;
        }

        & section:last-child {
          margin-bottom: 0.5rem;
        }
      }

      p {
        margin: 0;
      }

      div:last-child {
        & > p:first-child {
          font-weight: 400;
        }

        & > p:nth-of-type(2n) {
          font-size: 1rem;
          font-weight: 700;
          line-height: 20px;
        }
      }

      .details-summary\:monetary {
        display: flex;
        gap: var(--space-xs);

        & > div:last-child {
          gap: 0.25rem;
          justify-content: center;
        }

        .ui-component-voucher > div:first-child {
          padding-bottom: var(--image-height);

          & > div {
            align-items: center;
            background-image: -webkit-linear-gradient(right top, #65c3c2 20%, #5abfbe);
          }
        }
      }

      img {
        border-radius: 0.25rem;
        object-fit: cover;
        max-height: var(--image-height);
      }

      .details-summary\:package {
        font-weight: 700;

        .reviews__venue-rating {
          font-size: 0.75rem;
        }

        div:last-child {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          > div:last-child {
            display: block;
            flex-direction: row;
            align-items: center;
          }

          & > p:first-child {
            line-height: 20px;
            font-size: 1rem;
            font-weight: 700;
          }
        }
      }
    }

    .checkout\:payment-summary-and-trustpilot {
      table {
        width: 100%;
        border-collapse: collapse;
  
        th {
          font-weight: normal;
        }
  
        td {
          text-align: right;
          vertical-align: top;
        }
      }
  
      tbody {
        th,td {
          padding-bottom: var(--space-xs);
        }
  
        th {
          line-height: 22px;
          width: 75%;
  
          div:first-letter {
            text-transform: capitalize;
          }
        }
  
        &.promo-codes {
          border-top: 1px solid $grey-200;
  
          th, td {
            height: 60px;
            padding-top: var(--space-xs);
          }
        }
      }
  
      tfoot {
        font-size: 21px;
  
        th, td {
          font-weight: 600;
        }
      }
  
      .ui-component-notice {
        margin-top: var(--space);
      }
  
      .ui-component-klarna-widget {
        margin-top: var(--space-sm);
  
        a {
          color: inherit;
          text-decoration: underline;
        }
      }
    }
  }

  [hidden] {
    display: none;
  }

  .treatment-terms {
    font-size: 0.875rem;
  }
}
