@import "config/config";

/*
  <article
    class="ui-component-drawer
           gap:{sm*, md}
           summary:{default*, collapse}
           container:{default*, none}"
    // Optional. If omitted the drawer is static and will maintain its default state (open/closed).
    // Use `drawer:*` classes instead of `data-drawer-target` attributes for styling.
    data-controller="drawer"
    // Optional. Collapse summary when drawer open.
    data-drawer-collapse-summary-value="true"
    // Optional. Set drawer state to open by default.
    data-drawer-open-value="true"
  >
    <section data-drawer-target="summary">
      Summary content here
      <span class="drawer:cta" data-action="click->drawer#toggle">
        CTA text
      </span>
    </section>
    <section data-drawer-target="content">
      <div>
        Content here
        // Optional. Use when `data-drawer-collapse-summary-value` is true.
        <span class="drawer:cta" data-action="click->drawer#toggle">
          CTA text
        </span>
      </div>
    </section>
  </article>
*/

.ui-component-drawer {
  --drawer-content-gap: var(--space-sm);

  display: flex;
  flex-direction: column;
  gap: 0; // For transition on open
  overflow: clip; // `clip` instead of `hidden`, for `scrollIntoView`
  width: 100%;
  padding: var(--space-sm);
  background-color: #fff;
  border-radius: $ui-border-radius-lg;
  box-shadow: $ui-box-shadow-heavy;

  &.gap\:md {
    --drawer-content-gap: var(--space);
  }

  &.container\:none {
    padding: 0;
    background-color: transparent;
    box-shadow: none;

    // No clipped overflow for static drawers
    &.drawer\:open {
      overflow: visible;
    }
  }

  .drawer\:cta {
    user-select: none;
    cursor: pointer;
  }

  .drawer\:summary,
  [data-drawer-target="summary"] {
    display: flex;
    flex-direction: column;
    gap: var(--drawer-content-gap);
    transition: opacity 0.3s;

    &:has(.drawer\:cta) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  .drawer\:content,
  [data-drawer-target="content"] {
    display: grid;
    grid-template-rows: 0fr;
    opacity: 0;
    transition: grid-template-rows ease 0.3s, opacity 0.2s, margin-top 0.3s; // `margin-top` added/removed by JS

    > :first-child {
      min-height: 0; // So animation works as expected
    }
  }

  &.drawer\:open,
  &[data-drawer-open-value="true"] {
    gap: var(--drawer-content-gap);
    transition: gap 0.2s;

    &.summary\:collapse {
      gap: 0;

      .drawer\:summary,
      [data-drawer-target="summary"] {
        opacity: 0;
        pointer-events: none;
      }
    }

    .drawer\:content,
    [data-drawer-target="content"] {
      grid-template-rows: 1fr;
      opacity: 1;
    }
  }

  &.variant\:package-selection {
    header {
      font-size: var(--font-lg);
      font-weight: 700;
      line-height: 1.5;

      > :last-child:not(:first-child) {
        font-size: var(--base-font);
        font-weight: 300;
      }
    }

    .drawer\:summary > div:has(.fa-circle-check),
    [data-drawer-target="summary"] > div:has(.fa-circle-check) {
      display: flex;
    }

    .drawer\:content,
    [data-drawer-target="content"] {
      form,
      > div > details > summary > section  {
        display: flex;
        flex-direction: column;
        gap: var(--space);
      }
    }

    [hidden] {
      display: none;
    }
  }

  &.variant\:monetary-voucher-amount {
    .ui-component-list.variant\:selection {
      gap: var(--space-xs);
    }

    // Custom amount input
    .ui-component-input.variant\:floating-label {
      > i {
        top: var(--space-3xs);
      }

      input[type="text"] {
        padding-top: var(--space-sm);
        padding-right: var(--space-sm);
        padding-bottom: var(--space-sm);
      }

      .input-label {
        // Half of input height: border + padding + line-height
        top: calc(1px + var(--space-sm) + var(--base-font) * 1.5 / 2);
        transform: translateY(-50%);
      }

      &:focus-within .input-label,
      &.has-input .input-label,
      [placeholder]:not(:placeholder-shown) ~ .input-label,
      :autofill + .input-label,
      :-webkit-autofill + .input-label {
        top: 0;
        left: var(--space-2xs);
        transform: translateY(-50%);
      }
    }
  }
}
