.package-modal {
  --margin-top: 3rem;

  display: flex;
  flex-direction: column;
  height: calc(100% - var(--margin-top));
  background-color: #fff;
  overflow: hidden;
  margin-top: var(--margin-top);

  .venue__package + & {
    margin-top: 0;
    height: calc(100% - 2 * var(--margin-top));
  }

  .ui-component-modal:has(&) {
    > [tabindex] {
      overflow-y: hidden; // We want the scrolling inside `.package-modal__body`
      padding: 0 !important;
    }

    .calendar {
      padding: 0 $space;
      overflow-x: auto;
    }
  }
}

.package-modal__header {
  position: relative; // For absolute __close
}

.package-modal__close {
  position: absolute;
  top: sb-rem($spacing-unit--small);
  left: sb-rem($spacing-unit--small);
  cursor: pointer;
  font-size: sb-rem($base-font-size * 1.5);
  line-height: 1;
}

.package-modal__title {
  padding: em($spacing-unit--small);
  background-color: $grey--lighter;
  text-align: center;
  font-weight: bold;
  font-size: sb-rem($base-font-size * 1.2);
  line-height: 1;

  .summary {
    background: transparent;
  }

  .summary-item {
    padding: 0;
  }
}

.package-modal__body,
.package-modal__filters {
  padding: em($spacing-unit--small);

  @include media-query(tab-and-up) {
    padding-left: em($spacing-unit);
    padding-right: em($spacing-unit);
  }
}

.package-modal__body {
  flex: 1 0;
  overflow-y: auto;
  text-align: center;
}

.package-modal__filter {
  display: flex;
  justify-content: space-between;
  text-align: left;

  & + & {
    margin-top: em($spacing-unit--small);
    padding-top: em($spacing-unit--small);
    border-top: 1px solid $grey--lighter;
  }

  .filter-title {
    margin-bottom: 0;
    font-weight: bold;
    font-size: sb-rem($base-font-size * 1.2);
    line-height: 1;
  }
}

.package-modal__room-group.cy-room-group {
  row-gap: var(--space) !important;
  overflow: visible !important; // So tooltips show outside of container
  container-type: inline-size;

  > [data-img] {
    flex-basis: 40% !important;
    aspect-ratio: 2 !important;
    margin-bottom: 0 !important;
    align-self: unset !important;
    border-radius: $ui-border-radius-lg;
    @container (width >=480px) {
      aspect-ratio: 1.5 !important;
    }

    // Text for fallback image
    > span {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: var(--space-3xs);
      background: rgba(#fff, .5);
      text-align: center;
      @include text-small;
    }
  }

  &:has([data-img]) > div > section > div {
    gap: var(--space-xs);
    margin-bottom: var(--space-xs) !important;

    @media screen and (min-width: 554px) {
      flex-direction: column;
    }
  }

  > div:not([data-img]) > section {
    display: flex;
    flex-direction: column;
    gap: var(--space-sm);

    header {
      font-size: $base-font * $scale;
      font-weight: 700;
    }

    > div {
      display: flex;
      gap: var(--space);
      margin-bottom: var(--space-xs) !important;
    }
  }

  details {
    margin-top: 0 !important;

    // Line-clamp on content will not take `ul` into account unless `p` before it is also
    // long enough to be clamped. Add faux lines to any `p` that precedes a list to ensure
    // line-clamp will work as expected even if `p` is short.
    &:not([open]) > summary p:first-child:has(+ ul)::after {
      display: block;
      content: "\A\A";
      white-space: pre-wrap;
    }

    @include mobile-max {
      &:not([open]) > summary > section {
        height: 0;
        overflow: hidden;

        + span {
          margin-top: 0;
        }
      }
    }

    ul {
      display: inline-block; // So line-clamp on parent works as expected
      width: 100%;
      column-count: 2;
      column-gap: var(--space);
      padding-left: 0;
      padding-inline-start: 1em;
      box-sizing: border-box; // Explicitly required for Safari

      li {
        break-inside: avoid-column;
      }
    }
  }
}

.package-modal__room-group-old {
  display: flex;
  flex-wrap: wrap;
  column-gap: sb-rem($spacing-unit);
  text-align: left;

  & + & {
    border-top: $ui-border;
  }

  > :first-child {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    align-items: baseline;
    column-gap: 1ch;

    @include media-query(palm-and-up) {
      flex-direction: column;
    }
  }

  > :last-child {
    flex-grow: 1;
  }
}

.package-modal__room-row {
  display: grid;
  grid-template-columns: 1fr 2fr auto;
  align-items: center;
  column-gap: sb-rem($spacing-unit);

  & + & {
    margin-top: sb-rem($spacing-unit--small);
    padding-top: sb-rem($spacing-unit--small);
    border-top: 1px solid $grey--light;
  }
}

.package-modal__room-grid {
  --row-gap: var(--space);
  --col-gap: clamp(var(--space-xs), var(--space-xs) + var(--viewport-ratio) , var(--row-gap));
  display: grid;
  grid-template-columns: minmax(40px, auto) 1fr auto;
  align-items: stretch;
  row-gap: var(--row-gap);
  column-gap: var(--col-gap);
  overflow: visible; // So tooltips are visible

  width: calc(100% + 2 * var(--card-space)) !important;
  margin-top: calc(-1 * var(--card-space) / 2) !important;
  margin-left: calc(-1 * var(--card-space));
  margin-right: calc(-1 * var(--card-space));

  > :nth-child(3n+1) {
    padding-left: var(--card-space);
  }

  > :nth-child(3n) {
    padding-right: var(--card-space);
  }

  > * {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;

    &::after {
      content: "";
      position: absolute;
      background-color: $grey--light;
      z-index: 1;
      width: calc(100% + var(--col-gap));
      height: 1px;
      top: calc(var(--row-gap) / -2);
      left: 0;
    }

    &:nth-child(3n)::after {
      width: 100%;
    }
  }

  > :has(.package-modal__room-row__price) {
    row-gap: 0;

    .package-modal__room-row__price + * {
      flex-basis: 100%;
      line-height: 1;
    }
  }
}

.package-modal__room-row__price {
  display: flex;

  > :first-child {
    font-size: $base-font * $scale;
    font-weight: 700;
  }
}
