/**
  * Markup:
  * <aside class="ui-component-klarna-widget">
  *   <img> // Klarna badge
  *   <span></span>
  * </aside>
  */
.ui-component-klarna-widget {
  display: flex;
  justify-content: center;
  gap: var(--space-xs);
  padding: var(--space-2xs) var(--space-xs);
  border: $ui-border;
  border-radius: 3px;
  font-size: 14px;

  &.variant\:no-border {
    border: none;
    padding: 0;
    justify-content: start;
  }

  &.size\:sm {   
    font-size: 12px;
    gap: 8px;
    color: $grey-300;
  }

  a {
    text-decoration: underline;
  }
}
