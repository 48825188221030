@import "config/config";
@import "helpers/fontawesome"; // fa-pseudo

/**
  * Markup:
  * <button
  *   class="ui-component-tooltip position:{right*, bottom}
  *          --active" // set via JS
  *   data-controller="tooltip" // Tooltip messages set via params to controller functions
  * ></button>
  */
.ui-component-tooltip {
  --icon-content: "\f05a"; // fa-circle-info

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  padding: 0;
  line-height: inherit;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: underline;
  outline: none;
  border: none;
  color: $sb-green;
  background: transparent;

  .theme\:premium & {
    color: $sb-gold;
  }

  &:not([data-tooltip-icon="false"]) {
    &[data-action*="copy"] {
      --icon-content: "\f328"; // fa-clipboard
    }

    &::after {
      @include fa-pseudo(var(--icon-content));
      margin-left: 0.75ch;
    }
  }

  i {
    color: inherit;
  }

  &.--active {
    position: relative;

    &::before {
      z-index: $z-index-modal;
      position: absolute;
      top: calc(50% - var(--y-offset, 0px));
      left: calc(100% + 0.75ch - var(--x-offset, 0px));
      transform: translateY(-50%);
      display: inline-block;
      content: var(--tooltip-message);
      white-space: normal;
      width: max-content;
      max-width: 200px;
      padding: 0.25ch 0.5ch;
      background: rgba($black, .95);
      color: #fff;
      border-radius: 3px;
      font-size: 14px;
      line-height: 1.5;
    }

    &.position\:bottom::before {
      top: calc(100% - var(--y-offset, 0px));
      left: calc(50% - var(--x-offset, 0px));
      transform: translateX(-50%);
    }
  }
}
