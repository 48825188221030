.meal-choice {
  align-items: center;
  cursor: pointer;

  legend[hidden] ~ & {
    gap: 0.5ch;

    input {
      display: none;

      ~ .fa-circle-check {
        display: inline-block;
      }
    }
  }

  > span {
    display: flex;
    justify-content: space-between;
    width: 100%;

    fieldset:has(legend[hidden]) &::after {
      display: inline-block;
      content: "Edit";
      color: $green;
    }
  }
}

.selection-summary {
  position: relative;
  margin-bottom: $spacing-unit--small;

  .content {
    max-width: 90%;
  }

  .selection-summary__change {
    color: $green;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .checkmark {
    color: $green;
  }
}

.nested-group {
  .treatment-option {
    padding-left: $spacing-unit;
  }
}

.meal-choice,
.treatment-option__checkbox {
  display: flex;
  gap: 0.75ch;
}

.treatment-option__text {
  &:hover {
    cursor: pointer;
  }
}

.treatment-option__title {
  margin-bottom: $spacing-unit--small;
}
